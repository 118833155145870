import "./score.scss";
import heads from "../../assets/heads.png";
import tails from "../../assets/tails.png";
import { useEffect, useState } from "react";

const Score = ({ isHighScore, scoreDoc, rank }) => {
    let [strikeType, setStrikeType] = useState("heads");

    useEffect(() => {
        if (scoreDoc) {
            if (isHighScore) {
                setStrikeType(scoreDoc?.highScoreStrikeType || "heads");
            } else {
                setStrikeType(scoreDoc?.currentStrikeType || "heads");
            }
        }
    }, [scoreDoc]);

    return (
        <div
            className={`score ${isHighScore ? "high-score" : "current-score"}`}
        >
            <div className="score-container">
                <span className="score-label">
                    {isHighScore ? "Best Strike" : "Current Strike"}
                </span>
                <div className="score-details">
                    <span className="strike-value">
                        {isHighScore
                            ? scoreDoc?.highScore
                            : scoreDoc?.currentScore}
                    </span>
                    <span className="strike-icon">
                        <img
                            src={strikeType === "heads" ? heads : tails}
                            alt={strikeType}
                        />
                    </span>
                </div>
            </div>
            <span className="rank">Rank #{rank}</span>
        </div>
    );
};

export default Score;
