import { firestore, auth, functions } from "../firebase.config";
import { httpsCallable } from "firebase/functions";
import {
    doc,
    getDoc,
    getDocs,
    collection,
    query,
    where,
} from "firebase/firestore";

const getUserScore = async (user) => {
    if (!user && !auth.currentUser) {
        return { error: "User not logged in" };
    }

    const uid = user ? user.uid : auth.currentUser.uid;
    if (!uid) {
        return { error: "User not logged in" };
    }

    const scoreDoc = await getDoc(doc(firestore, "score", uid));

    if (!scoreDoc.exists()) {
        return { error: "User score not found" };
    }

    return scoreDoc.data();
};

const getRank = async (user, isHighScore, score) => {
    if (!score) {
        score = await getUserScore(user);
    }

    if (score.error) {
        return score;
    }

    const scoreToRank = isHighScore ? score.highScore : score.currentScore;
    const rank = await getDocs(
        query(
            collection(firestore, "score"),
            where("highScore", ">=", scoreToRank)
        )
    ).then((snapshot) => snapshot.size);
    return rank;
};

const canFlipACoin = async (user, score) => {
    if (!score) {
        score = await getUserScore(user);
    }

    if (score.error) {
        return score;
    }

    const canFlip =
        getNowUtcDate().toUTCString() !==
        getDateUtc(score.lastFlipTime.toDate()).toUTCString();
    return canFlip;
};

const getNextFlipTime = async (user, score) => {
    if (!score) {
        score = await getUserScore(user);
    }

    if (score.error) {
        return score;
    }

    const lastFlipTime = getDateUtc(score.lastFlipTime.toDate());
    const nextFlipTime = new Date(lastFlipTime);
    nextFlipTime.setUTCDate(nextFlipTime.getUTCDate() + 1);
    return nextFlipTime;
};

function getNowUtcDate() {
    var d = new Date();
    return getDateUtc(d);
}

function getDateUtc(d) {
    return new Date(
        Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate())
    );
}

const flipACoin = httpsCallable(functions, "flipACoin");

const updateUsername = httpsCallable(functions, "updateUsername");

const isUsernameSet = (score) => {
    return score?.username !== undefined && score.username !== "";
};

const isUsernameInUse = async (username) => {
    const snapshot = await getDocs(
        query(collection(firestore, "score"), where("username", "==", username))
    );

    return snapshot.size > 0;
};

export {
    getUserScore,
    getRank,
    canFlipACoin,
    flipACoin,
    getNextFlipTime,
    isUsernameSet,
    updateUsername,
    isUsernameInUse,
};
