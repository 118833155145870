import "./flipper.scss";
import heads from "../../assets/heads.png";
import tails from "../../assets/tails.png";
import { getUserScore, flipACoin, canFlipACoin } from "../../services/score";
import { useEffect, useState } from "react";
import { useAuth } from "../../services/auth";
import { userFlipCoin } from "../../services/events";

const Flipper = ({ scoreDoc, setScoreDoc }) => {
    const [strikeType, setStrikeType] = useState("");
    const [isFlipping, setIsFlipping] = useState(false);
    const { user } = useAuth();

    useEffect(() => {
        if (scoreDoc && strikeType === "") {
            setStrikeType(scoreDoc.currentStrikeType);
        }
    }, [scoreDoc]);

    const onClickFlipACoin = async () => {
        if (isFlipping) {
            return;
        }

        if (!scoreDoc) {
            return;
        }
        const canFlip = await canFlipACoin(user, scoreDoc);
        if (!canFlip) {
            return;
        }

        setIsFlipping(true);
        setStrikeType(scoreDoc.currentStrikeType);
        userFlipCoin();
        const newScore = await flipACoin();
        if (newScore?.data?.currentStrikeType === "heads") {
            if (scoreDoc?.currentStrikeType === "heads") {
                setStrikeType("animation-heads-heads");
            } else {
                setStrikeType("animation-tails-heads");
            }
        } else if (newScore?.data?.currentStrikeType === "tails") {
            if (scoreDoc?.currentStrikeType === "tails") {
                setStrikeType("animation-tails-tails");
            } else {
                setStrikeType("animation-heads-tails");
            }
        }

        setTimeout(() => {
            getUserScore(user).then((scoreDoc) => {
                setScoreDoc(scoreDoc);
            });
            setIsFlipping(false);
        }, 5000);
    };

    return (
        <div id="flipper">
            <div id="coin" className={strikeType} onClick={onClickFlipACoin}>
                <img src={heads} alt="Heads" className="side-a" />
                <img src={tails} alt="Tails" className="side-b" />
            </div>
        </div>
    );
};

export default Flipper;
