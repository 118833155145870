import "./game.scss";
import React, { useState, useEffect } from "react";
import { getUserScore, getRank, isUsernameSet } from "../../services/score";
import { protectAuthRoutes } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import Score from "../../components/score/score";
import Flipper from "../../components/coin-flipper/flipper";
import Message from "../../components/message/message";
import { useAuth } from "../../services/auth";
import { getNextFlipTime } from "../../services/score";
import Loader from "../../components/loader/loader";

const Home = () => {
    const [scoreDoc, setScoreDoc] = useState(null);
    const [heighScoreRank, setHighScoreRank] = useState(null);
    const [currentScoreRank, setCurrentScoreRank] = useState(null);
    const [nextFlipTime, setNextFlipTime] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { user, loading } = useAuth();

    useEffect(() => {
        if (!loading) {
            protectAuthRoutes(navigate, user);
        }

        if (user !== null && !loading) {
            getUserScore(user).then((score) => {
                if (score.error) {
                    console.log("error", score.error);
                    return;
                }

                const isUsernameExistsInDoc = isUsernameSet(score);
                if (!isUsernameExistsInDoc) {
                    navigate("/login?step=username");
                    return;
                }

                setScoreDoc(score);
                getRank(user, true, score).then((rank) =>
                    setHighScoreRank(rank)
                );
                getRank(user, false, score).then((rank) =>
                    setCurrentScoreRank(rank)
                );
                getNextFlipTime(user, score).then((time) =>
                    setNextFlipTime(time)
                );
            });
        }
    }, [loading]);

    const loadData = async () => {
        await getRank(user, true, scoreDoc).then((rank) =>
            setHighScoreRank(rank)
        );
        await getRank(user, false, scoreDoc).then((rank) =>
            setCurrentScoreRank(rank)
        );
        await getNextFlipTime(user, scoreDoc).then((time) =>
            setNextFlipTime(time)
        );
    };

    useEffect(() => {
        if (scoreDoc) {
            loadData().then(() => setIsLoading(false));
        }
    }, [scoreDoc]);

    return (
        <section className="game">
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <section className="section-score">
                        <Score scoreDoc={scoreDoc} rank={currentScoreRank} />
                        <Score
                            isHighScore
                            scoreDoc={scoreDoc}
                            rank={heighScoreRank}
                        />
                    </section>

                    <section className="section-flipper">
                        <Flipper
                            scoreDoc={scoreDoc}
                            setScoreDoc={setScoreDoc}
                        />
                    </section>

                    <section className="section-message">
                        <Message
                            scoreDoc={scoreDoc}
                            nextFlipTime={nextFlipTime}
                        />
                    </section>
                </>
            )}
        </section>
    );
};

export default Home;
