import "./App.scss";
import ErrorPage from "./pages/error/error";
import Game from "./pages/game/game";
import Login from "./pages/login/login";
import Layout from "./components/layout/layout";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { initGA } from "./services/events";

initGA();

const router = createBrowserRouter([
    {
        element: <Layout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <Game />,
            },
            {
                path: "/login",
                element: <Login />,
            },
        ],
    },
]);

function App() {
    return (
        <div>
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
