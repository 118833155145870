// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getPerformance } from "firebase/performance";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBKD-VY6e8zMRfkIRxybyLnl0_NvHEofMI",
    authDomain: "coinflip-403422.firebaseapp.com",
    projectId: "coinflip-403422",
    storageBucket: "coinflip-403422.appspot.com",
    messagingSenderId: "309794421374",
    appId: "1:309794421374:web:0c8288147f750b4e9c362f",
    measurementId: "G-6RE0XG0D8K",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const perf = getPerformance(app);
const firestore = getFirestore(app);
const functions = getFunctions(app);

export { app, auth, firestore, functions, perf, analytics };
