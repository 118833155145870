import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase.config";
import { useEffect } from "react";

export default function ErrorPage() {
    const navigate = useNavigate();

    useEffect(() => {
        if (auth) {
            navigate("/");
        } else {
            navigate("/login");
        }
    }, [navigate]);

    return <></>;
}
