import {
    signInWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
    createUserWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../firebase.config";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase.config";
import { userLoggedIn, userLoggedOut, userSignUp } from "./events";

const loginWithEmailAndPassword = async (email, password) => {
    try {
        userLoggedIn();
        return await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
        if (error && error.code === "auth/user-not-found") {
            return { error: "User not found. Please sign up." };
        }

        if (error && error.code === "auth/invalid-login-credentials") {
            return { error: "Invalid login credentials. Please try again." };
        }

        if (error && error.code === "auth/too-many-requests") {
            return { error: "Too many requests. Try again later." };
        }

        if (error && error.code === "auth/invalid-email") {
            return {
                error: "Invalid email. Please enter a valid email address.",
            };
        }

        return Promise.reject(error);
    }
};

const signupWithEmailAndPassword = async (email, password) => {
    try {
        userSignUp();
        return await createUserWithEmailAndPassword(auth, email, password);
    } catch (error) {
        console.log("error", error?.message);
        if (error && error.code === "auth/email-already-in-use") {
            return {
                error: "Email already in use. Please enter another email.",
            };
        }

        if (error && error.code === "auth/invalid-email") {
            return {
                error: "Invalid email. Please enter a valid email address.",
            };
        }

        if (error && error.code === "auth/weak-password") {
            return {
                error: "Weak password. Please enter a stronger password.",
            };
        }

        return Promise.reject(error);
    }
};

const isEmailInUse = async (email) => {
    const scoreDoc = await getDoc(doc(firestore, "users", email));

    if (!scoreDoc.exists()) {
        return false;
    }

    return true;
};

const loginWithGoogle = async () => {
    try {
        userLoggedIn();
        const provider = new GoogleAuthProvider();
        return await signInWithPopup(auth, provider);
    } catch (error) {
        return Promise.reject(error);
    }
};

const logout = async () => {
    try {
        userLoggedOut();
        return await signOut(auth);
    } catch (error) {
        return Promise.reject(error);
    }
};

const protectAuthRoutes = (navigate, user) => {
    if (user === null) {
        navigate("/login");
    }
};

const redirectIfLoggedIn = (navigate, user) => {
    if (user) {
        navigate("/");
    }
};

const useAuth = () => {
    const [userContext, setUserContext] = useState(() => {
        const context = {
            user: auth?.currentUser,
            loading: true,
        };
        return context;
    });

    function onChange(user) {
        if (user) {
            const context = {
                user: user,
                loading: false,
            };
            setUserContext(context);
        } else {
            const context = {
                user: null,
                loading: false,
            };
            setUserContext(context);
        }
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(onChange);
        return () => unsubscribe();
    }, []);

    return userContext;
};

export {
    loginWithEmailAndPassword,
    loginWithGoogle,
    logout,
    signupWithEmailAndPassword,
    isEmailInUse,
    protectAuthRoutes,
    redirectIfLoggedIn,
    useAuth,
};
