import "./layout.scss";
import { Outlet } from "react-router-dom";
import Header from "../header/header";

const Layout = () => {
    return (
        <div id="layout">
            <Header />
            <Outlet />
        </div>
    );
};

export default Layout;
