import ReactGA from "react-ga";

export const initGA = () => {
    ReactGA.initialize("G-6RE0XG0D8K");
};

export const userLoggedIn = () => {
    ReactGA.event({
        category: "User Action",
        action: "user_login",
    });
};

export const userLoggedOut = () => {
    ReactGA.event({
        category: "User Action",
        action: "user_logout",
    });
};

export const userSignUp = () => {
    ReactGA.event({
        category: "User Action",
        action: "user_sign_up",
    });
};

export const userFlipCoin = () => {
    ReactGA.event({
        category: "User Action",
        action: "flip",
    });
};
