import "./message.scss";
import { useEffect, useState } from "react";
import { useCountdown } from "../../services/countdown";
import { nextFlipTime } from "../../services/score";

const Message = ({ scoreDoc, nextFlipTime }) => {
    const Ready = () => {
        return (
            <div className="ready">
                <h3>Click the coin</h3>
            </div>
        );
    };

    const ShowCounter = ({ days, hours, minutes, seconds }) => {
        return (
            <div className="show-counter-wrapper">
                <h3>Next run in</h3>
                <div className="show-counter">
                    <DateTimeDisplay
                        value={hours}
                        type={"Hours"}
                        isDanger={false}
                    />
                    <span>:</span>
                    <DateTimeDisplay
                        value={minutes}
                        type={"Mins"}
                        isDanger={false}
                    />
                    <span>:</span>
                    <DateTimeDisplay
                        value={seconds}
                        type={"Seconds"}
                        isDanger={false}
                    />
                </div>
            </div>
        );
    };

    useEffect(() => {
        updateTargetDate(nextFlipTime);
    }, [nextFlipTime]);

    const { days, hours, minutes, seconds, updateTargetDate } =
        useCountdown(nextFlipTime);

    return (
        <div className="message">
            {days + hours + minutes + seconds <= 0 ? (
                <Ready />
            ) : (
                <ShowCounter
                    days={days}
                    hours={hours}
                    minutes={minutes}
                    seconds={seconds}
                />
            )}
        </div>
    );
};

const DateTimeDisplay = ({ value, type, isDanger }) => {
    return (
        <div className={isDanger ? "countdown danger" : "countdown"}>
            {" "}
            <span className="value"> {value}</span>{" "}
            <span className="type"> {type}</span>{" "}
        </div>
    );
};

export default Message;
