import "./header.scss";
import { useAuth, logout } from "../../services/auth";
import logoutIcon from "../../assets/logout.svg";
import leaderboard from "../../assets/leaderboard.svg";
import questionMark from "../../assets/question-mark.svg";
import { useNavigate } from "react-router-dom";

const Header = () => {
    const { user } = useAuth();
    const navigate = useNavigate();

    const navigateToLeaderboard = () => {
        navigate("/leaderboard");
    };

    const logoutGame = () => {
        logout().then(() => navigate("/login?step=main"));
    };

    return (
        <header id="header">
            <div className="placeholder"></div>
            <h1>Flip A Coin</h1>
            <menu>
                <button>
                    <img src={questionMark} alt="help" className="help" />
                </button>
                {user && (
                    <>
                        <button onClick={navigateToLeaderboard}>
                            <img
                                src={leaderboard}
                                alt="leaderboard"
                                className="leaderboard"
                            />
                        </button>
                        <button onClick={logoutGame}>
                            <img
                                src={logoutIcon}
                                alt="logout"
                                className="logout"
                            />
                        </button>
                    </>
                )}
            </menu>
        </header>
    );
};

export default Header;
