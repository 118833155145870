import { useEffect, useState } from "react";

const useCountdown = (initialTargetDate) => {
    const [countDownDate, setCountDownDate] = useState(
        new Date(initialTargetDate).getTime()
    );

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );

    const updateTargetDate = (newTargetDate) => {
        setCountDownDate(new Date(newTargetDate).getTime());
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return { ...getReturnValues(countDown), updateTargetDate };
};

const getReturnValues = (countDown) => {
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
};

export { useCountdown };
