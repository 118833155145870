import "./login.scss";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../services/auth";
import {
    redirectIfLoggedIn,
    signupWithEmailAndPassword,
    loginWithEmailAndPassword,
    loginWithGoogle,
    isEmailInUse,
} from "../../services/auth";
import {
    getUserScore,
    updateUsername,
    isUsernameSet,
    isUsernameInUse,
} from "../../services/score";
import { useQuery } from "../../services/utils";
import alert from "../../assets/alert.svg";
import google from "../../assets/google.svg";

const Login = () => {
    const navigate = useNavigate();
    let query = useQuery();
    const steps = ["main", "log-in-email", "sign-up-email", "username"];

    const getStep = () => {
        const step = query.get("step");
        if (step && steps.includes(step)) {
            return step;
        }
        return "main";
    };

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    const [message, setMessage] = useState("");
    const [step, setStep] = useState(getStep());
    const [focusedInput, setFocusedInput] = useState(null);
    const { user, loading } = useAuth();

    useEffect(() => {
        if (!loading) {
            getUserScore(user).then((score) => {
                if (score.error) {
                    setStep("main");
                    return;
                }

                const isUsernameExistsInDoc = isUsernameSet(score);
                if (isUsernameExistsInDoc) {
                    redirectIfLoggedIn(navigate, user);
                    return;
                } else {
                    setStep("username");
                    return;
                }
            });
        }
    }, [loading]);

    useEffect(() => {
        const step = getStep();
        if (step && step === "username" && !user) {
            setStep("main");
            return;
        }

        setStep(step);
    }, [query]);

    const basicLoginEmailOnly = async (e) => {
        e.preventDefault();
        if (email === "") {
            setMessage("Please enter an email address");
            return;
        }

        if (!isEmailValid(email)) {
            setMessage("Invalid email. Please enter a valid email address.");
            return;
        }

        try {
            const emailInUse = await isEmailInUse(email);
            if (emailInUse.error) {
                setMessage(emailInUse.error);
                return;
            }

            if (!emailInUse) {
                setMessage("");
                setStep("sign-up-email");
                return;
            }

            setMessage("");
            setStep("log-in-email");
        } catch (error) {
            console.log("error", error?.message);
            setMessage("Internal error");
        }
    };

    const isEmailValid = (email) => {
        const emailRegex = /\S+@\S+\.\S+/;
        return emailRegex.test(email);
    };

    const basicLogin = async (e) => {
        e.preventDefault();
        if (email === "") {
            setMessage("Please enter an email address");
            return;
        }
        if (password === "") {
            setMessage("Please enter a password");
            return;
        }
        try {
            const response = await loginWithEmailAndPassword(email, password);
            if (response.error) {
                setMessage(response.error);
                return;
            }

            const scoreDoc = await getUserScore(user);
            const isUsernameExistsInDoc = isUsernameSet(scoreDoc);
            if (isUsernameExistsInDoc) {
                navigate("/");
                return;
            } else {
                setMessage("");
                setStep("username");
                return;
            }
        } catch (error) {
            console.log("error", error?.message);
            setMessage("Internal error");
        }
    };

    const basicSignUp = async (e) => {
        e.preventDefault();
        if (email === "") {
            setMessage("Please enter an email address");
            return;
        }
        if (password === "") {
            setMessage("Please enter a password");
            return;
        }

        try {
            const response = await signupWithEmailAndPassword(email, password);
            if (response.error) {
                setMessage(response.error);
                return;
            }

            setMessage("");
            setStep("username");
        } catch (error) {
            console.log("error", error?.message);
            setMessage("Internal error");
        }
    };

    const loginWithGoogleProvider = async (e) => {
        e.preventDefault();
        try {
            const response = await loginWithGoogle();
            if (response.error) {
                setMessage(response.error);
                return;
            }

            const scoreDoc = await getUserScore(user);
            const isUsernameExistsInDoc = isUsernameSet(scoreDoc);
            if (isUsernameExistsInDoc) {
                navigate("/");
                return;
            } else {
                setMessage("");
                setStep("username");
                return;
            }
        } catch (error) {
            console.log("error", error?.message);
            setMessage("Internal error");
        }
    };

    const storeUsername = async (e) => {
        e.preventDefault();
        if (username === "") {
            setMessage("Please enter a username");
            return;
        }

        try {
            const usernameInUse = await isUsernameInUse(username);
            if (usernameInUse) {
                setMessage("Username already in use.");
                return;
            }

            const response = await updateUsername({ username });
            if (response?.data?.error) {
                setMessage(response.data.error);
                return;
            }

            navigate("/");
        } catch (error) {
            console.log("error", error?.message);
            setMessage("Internal error");
        }
    };

    const handleFocus = (input) => () => {
        setFocusedInput(input);
    };

    const handleBlur = (input) => () => {
        if (focusedInput === input) setFocusedInput(null);
    };

    return (
        <>
            <main className="login">
                <section className="header">
                    {(step === "main" && (
                        <h1>Log in or create an account</h1>
                    )) ||
                        (step === "log-in-email" && (
                            <h1>Log in to your account</h1>
                        )) ||
                        (step === "sign-up-email" && (
                            <h1>Create your account</h1>
                        )) ||
                        (step === "username" && <h1>Choose your username</h1>)}
                </section>
                {(step === "main" && (
                    <>
                        <section className="basic-login-email-only-form">
                            <fieldset className="fieldset">
                                <label htmlFor="email" className="label">
                                    Email Address
                                </label>
                                <div className="field-wrapper">
                                    <div
                                        className={
                                            focusedInput ===
                                            "basic-login-email-only-email"
                                                ? "field-box-focused"
                                                : "field-box"
                                        }
                                    >
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="input"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            onFocus={handleFocus(
                                                "basic-login-email-only-email"
                                            )}
                                            onBlur={handleBlur(
                                                "basic-login-email-only-email"
                                            )}
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            {message && (
                                <>
                                    <img
                                        className="alert-icon"
                                        src={alert}
                                        alt="error"
                                    />
                                    <div className="error-message">
                                        <span role="alert">{message}</span>
                                    </div>
                                </>
                            )}
                            <div className="button-wrapper">
                                <button
                                    className="submit-button"
                                    onClick={basicLoginEmailOnly}
                                >
                                    Continue
                                </button>
                            </div>
                        </section>
                        <section className="or-line">
                            <span>or</span>
                        </section>
                        <section className="providers-login">
                            <div className="provider-login-button-wrapper">
                                <button
                                    className="provider-login-button"
                                    onClick={loginWithGoogleProvider}
                                >
                                    <span className="provider-login-button-icon">
                                        <img
                                            src={google}
                                            alt="google"
                                            className="google-icon"
                                        />
                                    </span>
                                    <span className="provider-login-button-text">
                                        Continue with Google
                                    </span>
                                </button>
                            </div>
                        </section>
                    </>
                )) ||
                    (step === "log-in-email" && (
                        <section className="basic-login">
                            <fieldset className="fieldset">
                                <label htmlFor="email" className="label">
                                    Email Address
                                </label>
                                <div className="field-wrapper">
                                    <div
                                        className={
                                            focusedInput === "basic-login-email"
                                                ? "field-box-focused"
                                                : "field-box"
                                        }
                                    >
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="input"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            onFocus={handleFocus(
                                                "basic-login-email"
                                            )}
                                            onBlur={handleBlur(
                                                "basic-login-email"
                                            )}
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="fieldset">
                                <label htmlFor="password" className="label">
                                    Password
                                </label>
                                <div className="field-wrapper">
                                    <div
                                        className={
                                            focusedInput ===
                                            "basic-login-password"
                                                ? "field-box-focused"
                                                : "field-box"
                                        }
                                    >
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            className="input"
                                            value={password}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            onFocus={handleFocus(
                                                "basic-login-password"
                                            )}
                                            onBlur={handleBlur(
                                                "basic-login-password"
                                            )}
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            {message && (
                                <>
                                    <img
                                        className="alert-icon"
                                        src={alert}
                                        alt="error"
                                    />
                                    <div className="error-message">
                                        <span role="alert">{message}</span>
                                    </div>
                                </>
                            )}
                            <div className="button-wrapper">
                                <button
                                    className="submit-button"
                                    onClick={basicLogin}
                                >
                                    Log In
                                </button>
                            </div>
                        </section>
                    )) ||
                    (step === "sign-up-email" && (
                        <section className="basic-sign-up">
                            <fieldset className="fieldset">
                                <label htmlFor="email" className="label">
                                    Email Address
                                </label>
                                <div className="field-wrapper">
                                    <div
                                        className={
                                            focusedInput ===
                                            "basic-sign-up-email"
                                                ? "field-box-focused"
                                                : "field-box"
                                        }
                                    >
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="input"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            onFocus={handleFocus(
                                                "basic-sign-up-email"
                                            )}
                                            onBlur={handleBlur(
                                                "basic-sign-up-email"
                                            )}
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="fieldset">
                                <label htmlFor="password" className="label">
                                    Password
                                </label>
                                <div className="field-wrapper">
                                    <div
                                        className={
                                            focusedInput ===
                                            "basic-sign-up-password"
                                                ? "field-box-focused"
                                                : "field-box"
                                        }
                                    >
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            className="input"
                                            value={password}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            onFocus={handleFocus(
                                                "basic-sign-up-password"
                                            )}
                                            onBlur={handleBlur(
                                                "basic-sign-up-password"
                                            )}
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            {message && (
                                <>
                                    <img
                                        className="alert-icon"
                                        src={alert}
                                        alt="error"
                                    />
                                    <div className="error-message">
                                        <span role="alert">{message}</span>
                                    </div>
                                </>
                            )}
                            <div className="button-wrapper">
                                <button
                                    className="submit-button"
                                    onClick={basicSignUp}
                                >
                                    Create Account
                                </button>
                            </div>
                        </section>
                    )) ||
                    (step === "username" && (
                        <section className="username">
                            <fieldset className="fieldset">
                                <label htmlFor="username" className="label">
                                    Username
                                </label>
                                <div className="field-wrapper">
                                    <div
                                        className={
                                            focusedInput === "username"
                                                ? "field-box-focused"
                                                : "field-box"
                                        }
                                    >
                                        <input
                                            type="text"
                                            name="username"
                                            id="username"
                                            className="input"
                                            value={username}
                                            onChange={(e) =>
                                                setUsername(e.target.value)
                                            }
                                            onFocus={handleFocus("username")}
                                            onBlur={handleBlur("username")}
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            {message && (
                                <>
                                    <img
                                        className="alert-icon"
                                        src={alert}
                                        alt="error"
                                    />
                                    <div className="error-message">
                                        <span role="alert">{message}</span>
                                    </div>
                                </>
                            )}
                            <div className="button-wrapper">
                                <button
                                    className="submit-button"
                                    onClick={storeUsername}
                                >
                                    Finish
                                </button>
                            </div>
                        </section>
                    ))}
            </main>
        </>
    );
};

export default Login;
